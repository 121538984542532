<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table">
            <!-- <div
              class="
                d-flex
                justify-content-between
                flex-sm-nowrap flex-wrap
                gap-3
                my-3
              "
            >
              <div class="d-flex justify-content-between w-100">
                <button
                  type="submit"
                  class="btn btn-primary waves-effect waves-light py-2"
                >
                  Add Business
                </button>
              </div>
              <div class="search position-relative min_200">
                <input
                  class="form-control ps-5 table-light border-0"
                  list="datalistOptions"
                  id="searchList"
                  placeholder="Type to search..."
                />
                <i class="uil-search position-absolute top-0 px-3"></i>
              </div>
            </div> -->

            <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0 w-100"
              >
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px"></th>
                    <th>Business Name</th>
                    <th>User Name</th>
                    <th>Contact Number</th>
                    <th>Website</th>
                    <th>Profile Link</th>
                    <th>Request On</th>
                    <th>attachment</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr colspan="5" v-if="loading">
                    <td colspan="9">
                      <APILoader
                        :loading="loading"
                        class="align-middle text-center"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(claim, index) in claims" :key="index">
                    <th style="width: 20px"></th>
                    <td>
                      <span>{{ claim.vendorlisting.title }}</span>
                    </td>
                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          <div v-if="claim.user">
                            <img v-if="!claim.user.avatar"
                            src="../../../../../public/avatar.jpg"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          <img v-else
                          :src="claim.user.avatar"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          </div>
                          <span>{{
                            getSafe(() => claim.user.full_name, "N/A")
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ claim.contact_name }}
                    </td>
                    <td>
                      <a href="" class="text-dark pe-1"> {{ claim.website }}</a>
                    </td>
                    <td>
                      <a href="" class="text-dark pe-1">
                        {{ claim.linkedin }}
                      </a>
                    </td>
                    <td class="text-center">{{ claim.created_at }}</td>
                    <td>
                      <a
                        @click="claimAttachments(claim.media)"
                        href="javascript: void(0);"
                        v-b-modal.modal-3
                        >View Attachments</a
                      >
                    </td>
                    <td>
                      <a
                        style="margin: 10px"
                        @click="getId(claim.id)"
                        href="javascript: void(0);"
                        v-b-modal.modal-1
                        ><i data-toggle="tooltip" title="Reject" class="uil-times-circle f-24 text-danger"></i
                      ></a>
                      <a
                        @click="statusChange(claim.id, 'accepted')"
                        href="javascript: void(0);"
                        ><i data-toggle="tooltip" title="Accept" class="uil-check-circle f-24 text-info"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
    <rejected :claim_id="id" />
    <Attachments :media="media" />
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import rejected from "@/components/rejected-claim";
import Attachments from "../../../../components/attachments.vue";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Claim Business",
    meta: [
      {
        name: "request",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Attachments,
    rejected,
  },
  data() {
    return {
      loading: true,
      title: "Claim Requests",
      claims: null,
      id: null,
      media: [],
      items: [
        {
          text: "TipShare",
        },
        {
          text: "Claim Business",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.allPendingClaims();
  },
  methods: {
    randerData(response) {
      // console.log(response.data.data);
      this.claims = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          scrollX:true,
        fixedHeader:{
            header: true,
            footer: true
        },
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    allPendingClaims() {
      this.$axios
        .get("all-pending-claims")
        .then((response) => {
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getId(id) {
      this.id = id;
    },
    claimAttachments(data) {
      this.media = data;
    },
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
    statusChange(id, data) {
      let statusObj = {
        status: data,
        id: id,
      };
      this.swalAlert(statusObj);
    },
    swalAlert(statusObj) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.apiForStatusChange(statusObj);
          Swal.fire("Updated!", "Status has been Changed.", "success");
          this.allClaims();
        }
      });
    },
    apiForStatusChange(statusObj) {
      this.$axios
        .post("claim-status", statusObj)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>